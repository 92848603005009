import { Injectable } from '@angular/core';
import { QuestionActionBar } from '../models';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class QuestionControlService {
	toFormGroup(questions: QuestionActionBar<string>[]) {
		const group: any = {};

		questions.forEach((question) => {
			group[question.key] = question.required
				? new FormControl(question.value || '', Validators.required)
				: new FormControl(question.value || '');
		});
		return new FormGroup(group);
	}
}
