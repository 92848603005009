import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { IAlert } from '../interfaces';

@Injectable({
	providedIn: 'root',
})
export class ToasterService {
	confirmAlert(alert: IAlert): Promise<SweetAlertResult<boolean>> {
		return Swal.fire({
			title: alert.title,
			text: alert?.text || '',
			icon: alert.icon || 'warning',
			showCancelButton: true,
			confirmButtonText: alert.confirmButtonText || 'Si, confirmar',
			cancelButtonText: alert.cancelButtonText || 'No, cancelar!',
			reverseButtons: true,
			confirmButtonColor: '#0B5ED7',
			cancelButtonColor: '#BB2D3B',
		});
	}

	alertTop(alert: IAlert) {
		Swal.fire({
			position: 'top-end',
			icon: alert?.icon || 'success',
			title: alert.title,
			showConfirmButton: false,
			timer: alert?.timer || 1500,
		});
	}

	errorAction(alert: IAlert) {
		Swal.fire({
			icon: alert?.icon || 'error',
			title: alert.title,
			text: alert?.text || '',
		});
	}
}
