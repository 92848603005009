export const TypeStatusBill = [
	{
		value: -1,
		text: '-- Seleccionar --',
	},
	{
		value: 0,
		text: 'Anulada',
	},
	{
		value: 1,
		text: 'Recibida',
	},
	{
		value: 2,
		text: 'Cerrada',
	},
	{
		value: 3,
		text: 'Objetada',
	},
	{
		value: 4,
		text: 'Pagada',
	},
	{
		value: 5,
		text: 'Abierta',
	},
	{
		value: 6,
		text: 'Cargada',
	},
	{
		value: 7,
		text: 'Reabierta',
	},
	{
		value: 8,
		text: 'Pago Solicitado',
	},
	{
		value: 9,
		text: 'Rechazada',
	},
	{
		value: 10,
		text: 'Lista Para Pago',
	},
	{
		value: 11,
		text: 'Proceso De Trasferencia',
	},
	{
		value: 12,
		text: 'Pendiente recepción Física FarmaCard',
	},
	{
		value: 13,
		text: 'Cheque Reintegrado',
	},
];
