import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	private jwtHelperService = new JwtHelperService();
	token = 'token';

	writeToken(token: string): void {
		if (!token) {
			return;
		}
		localStorage.setItem(this.token, token);
	}

	readToken() {
		return localStorage.getItem(this.token);
	}

	readDecodeToken() {
		return (
			this.readToken() && this.jwtHelperService.decodeToken(this.readToken())
		);
	}

	removeToken(): void {
		localStorage.removeItem(this.token);
	}
}
