import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TitleService {
	private _title: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public title$ = this._title.asObservable();

	setTitle(title: string): void {
		this._title.next(title);
	}
}
