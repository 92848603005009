import { Signal, signal } from '@angular/core';
import { Observable, of } from 'rxjs';

export class QuestionActionBar<T> {
	action: (args?: any) => void;
	children: QuestionActionBar<T>[];
	class: string;
	color: string;
	controlType: string;
	disabled: boolean;
	hidden: Signal<boolean>;
	icon: string;
	id: string;
	key: string;
	label: string;
	label$: Observable<string>;
	loading$: Observable<boolean>;
	max: Date;
	min: Date;
	options: { value: any; text: string; action?: (args?: any) => void }[];
	order: number;
	placeholder: string;
	required: boolean;
	tooltip: string;
	type: string;
	value: T | undefined;

	constructor(
		options: {
			action?: (args?: any) => void;
			children?: QuestionActionBar<T>[];
			class?: string;
			color?: string;
			controlType?: string;
			disabled?: boolean;
			hidden?: Signal<boolean>;
			icon?: string;
			id?: string;
			key?: string;
			label?: string;
			label$?: Observable<string>;
			loading$?: Observable<boolean>;
			max?: Date;
			min?: Date;
			options?: { value: any; text: string; action?: (args?: any) => void }[];
			order?: number;
			placeholder?: string;
			required?: boolean;
			tooltip?: string;
			type?: string;
			value?: T;
		} = {}
	) {
		this.action = options.action || (() => {});
		this.children = options.children;
		this.class = options.class || '';
		this.color = options.color || '';
		this.controlType = options.controlType || '';
		this.disabled = options.disabled || false;
		this.hidden = options.hidden || signal<boolean>(false);
		this.icon = options.icon || '';
		this.id = this.id || '';
		this.key = options.key || '';
		this.label = options.label || '';
		this.label$ = options.label$;
		this.loading$ = options.loading$ || of(false);
		this.max = options.max;
		this.min = options.min;
		this.options = options.options || [];
		this.order = options.order === undefined ? 1 : options.order;
		this.placeholder = options.placeholder || '';
		this.required = !!options.required;
		this.tooltip = options.tooltip || '';
		this.type = options.type || '';
		this.value = options.value;
	}
}
