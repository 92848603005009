export class FormatEnumValue<T> {
	public text: string;
	public value: T;

	constructor(value: T) {
		this.text = this.formatEnumValue(value);
		this.value = value;
	}

	private formatEnumValue(value: T): string {
		const stringValue = String(value);
		return stringValue.replace(/([a-z])([A-Z])/g, '$1 $2');
	}
}
