import {
	MatDateFormats,
	MAT_NATIVE_DATE_FORMATS,
} from '@angular/material/core';

export const DateFormatDayFirst: MatDateFormats = {
	...MAT_NATIVE_DATE_FORMATS,
	display: {
		...MAT_NATIVE_DATE_FORMATS.display,
		dateInput: {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
		} as Intl.DateTimeFormatOptions,
	},
};
