export const AppRoutes = {
	Auth: 'auth',
	Login: 'login',
	Reclamacion: 'reclamacion',
	Reclamaciones: 'reclamaciones',
	Reclamadas: 'reclamadas',
	Reclamada: 'reclamada',
	Reclamar: 'reclamar',
	Facturacion: 'facturacion',
	Autorizacion: 'autorizacion',
	AutorizacionTitle: 'Autorizacion',
	Aprobados: 'aprobadas',
	Authenticacion: 'authenticacion',
	LoginUrl: 'Login',
	IniciarSesion: 'iniciar-sesion',
	Empty: '',
	WeatherForecast: 'WeatherForecast',
	GuardarLista: 'GuardarLista',
	Notificar: 'notificar',
	Devolucion: 'devolucion',
	Detail: 'detalle',
	Retencion: 'retencion',
	Carta: 'carta',
	Preview: 'preview',
	Api: 'api',
	Usuario: 'usuario',
	Certificado: 'certificado',
	ObtenerErroresReclamo: 'obtener-errores-reclamo',
	OFVPSS: 'ofv-pss',
	Crear: 'Crear',
	HistoricoReclamaciones: 'historicoreclamaciones',
	GetFactura: 'GetFactura',
	AnularReclamo: 'anular-reclamo',
	GetReclamaciones: 'GetReclamaciones',
	GetAutorizaciones: 'GetAutorizaciones',
	HistorialSolicitudes: 'Historial-Solicitudes',
	DescargarExcel: 'descargar-csv',
	DetalleSolicitud: 'detalle-solicitud',
};
