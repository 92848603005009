export const Title = {
	Auth: 'auth',
	Login: 'login',
	Devolucion: 'devolución',
	Notificar: 'notificar',
	Reclamaciones: 'historial de reclamaciones',
	Reclamar: 'reclamar factura',
	Reclamadas: 'historial de factura reclamadas',
	Detail: 'información de la Factura',
	CartaRetencion: 'carta Retención Impuestos',
	CartaPreview: 'vista previa carta retención',
	ReclamadaPreview: 'vista previa reclamada',
	Aprobados: 'aprobadas',
};
