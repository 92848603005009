import { IPaginator } from '../interfaces';

export class Pagination<T> {
	public pager: IPaginator;
	public data: T;

	constructor({ pager, data }: { pager: IPaginator; data: T }) {
		this.pager = pager;
		this.data = data;
	}
}
