import { Injectable } from '@angular/core';
import { FormatEnumValue } from '../models';

@Injectable({
	providedIn: 'root',
})
export class UtilsService {
	formattedToStringDate(fechaFactura: Date): string {
		const year: number = fechaFactura.getFullYear();
		const month: string = ('0' + (fechaFactura.getMonth() + 1)).slice(-2);
		const day: string = ('0' + fechaFactura.getDate()).slice(-2);

		return `${year}-${month}-${day}`;
	}

	formatEnumValue<T>(enumObject: Record<string, T>): FormatEnumValue<T>[] {
		return Object.values(enumObject).map(
			(value) => new FormatEnumValue<T>(value)
		);
	}
}
